<template>
    <!-- dropdown flat -->
    <b-dropdown
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        class="float-right"
        :text="currentLocale"
        variant="flat-secondary"
    >
        <b-dropdown-item
            v-for="(availableLocale, i) in availableLocales"
            :key="i"
            @click="handleDropdownClick(availableLocale)"
        >
            {{ getFullLocaleName(availableLocale) }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import { setCookie } from '@/libs/i18n/utils';
import { localize } from 'vee-validate';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
    components: {
        BDropdown,
        BDropdownItem,
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            locale: this.currentLocale,
        };
    },

    computed: {
        availableLocales() {
            return this.$i18n.availableLocales;
        },

        currentLocale() {
            const { locale } = this.$i18n;

            return this.getFullLocaleName(locale);
        },
    },

    watch: {
        locale(val) {
            this.$i18n.locale = val;
        },
    },

    methods: {
        handleDropdownClick(availableLocale) {
            this.locale = availableLocale;

            localize(availableLocale);

            setCookie({ value: availableLocale });
        },

        getFullLocaleName(locale) {
            switch (locale) {
                case 'ru':
                    return 'Русский';
                case 'en':
                    return 'English';
                default:
                    return 'English';
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
